@use 'sass:math';

$loader-color: #0052ec !default;
$loader-size: 10px !default;
$loader-animation-duration: 1s !default;

@function circle-angle($size) {
  @return round($size * 0.70710678118) + math.div($size, 2);
}

@function circle-normal($size) {
  @return round($size * 0.70710678118) + $size;
}

@mixin loader-rotate {
  @keyframes loader-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin loader-scale {
  @keyframes loader-scale {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}

@mixin loader07(
  $size: $loader-size,
  $color: $loader-color,
  $duration: $loader-animation-duration,
  $align: null
) {
  $unique-name: unique-id();
  $dot-color: rgba($color, 0.05), rgba($color, 0.1), rgba($color, 0.2),
    rgba($color, 0.3), rgba($color, 0.4), rgba($color, 0.6), rgba($color, 0.8),
    rgba($color, 1);
  width: $size;
  height: $size;
  border-radius: 50%;
  position: relative;
  animation: loader $duration linear infinite;

  @if ($align == center) {
    margin: 0 auto;
  }

  @if ($align == middle) {
    top: 50%;
    margin: -(math.div($size, 2)) auto 0;
  }

  @keyframes loader {
    0% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 1),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 2),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 3),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 4),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 5),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 6),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 7),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 8);
    }

    12.5% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 8),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 1),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 2),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 3),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 4),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 5),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 6),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 7);
    }

    25% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 7),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 8),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 1),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 2),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 3),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 4),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 5),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 6);
    }

    37.5% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 6),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 7),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 8),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 1),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 2),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 3),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 4),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 5);
    }

    50% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 5),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 6),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 7),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 8),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 1),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 2),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 3),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 4);
    }

    62.5% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 4),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 5),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 6),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 7),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 8),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 1),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 2),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 3);
    }

    75% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 3),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 4),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 5),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 6),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 7),
        // Bottom Lefts
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 8),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 1),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 2);
    }

    87.5% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 2),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 3),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 4),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 5),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 6),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 7),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 8),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 1);
    }

    100% {
      box-shadow: // Top
        0 (-(circle-normal($size))) 0 0 nth($dot-color, 1),
        // Top Right
        circle-angle($size) (-(circle-angle($size))) 0 0 nth($dot-color, 2),
        // Right
        circle-normal($size) 0 0 0 nth($dot-color, 3),
        // Bottom right
        circle-angle($size) circle-angle($size) 0 0 nth($dot-color, 4),
        // Bottom
        0 circle-normal($size) 0 0 nth($dot-color, 5),
        // Bottom Left
        (-(circle-angle($size))) circle-angle($size) 0 0 nth($dot-color, 6),
        // Left
        (-(circle-normal($size))) 0 0 0 nth($dot-color, 7),
        // Top left
        (-(circle-angle($size))) (-(circle-angle($size))) 0 0 nth($dot-color, 8);
    }
  }
}

@mixin loadingDots($color) {
  &:after {
    content: ".";
    animation: dots 2s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: $color;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 $color, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 $color, 0.5em 0 0 $color;
    }
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

@import "./mixins";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-200;
}

.avatar {
  min-width: 36px;
  @apply rounded-full bg-green-800 text-white w-9 h-9 flex items-center justify-center dark:bg-gray-600;
}

.primary-button {
  @apply bg-green-800 rounded-md uppercase text-12 text-white px-6 py-3 dark:bg-gray-900 dark:text-gray-300;
}

.document-button {
  @apply max-w-min h-9 bg-green-800 text-white flex items-center px-3 rounded-md cursor-pointer dark:bg-gray-900 dark:text-gray-300;
}

.transparent-button {
  @apply text-green-800 rounded-md uppercase text-12 px-6 py-3 hover:bg-gray-100
  dark:text-gray-300 dark:hover:text-green-800;
}

.always-green-button {
  @apply bg-green-800 text-white rounded-md uppercase text-12 px-6 py-3 dark:text-gray-300 w-40 font-bold;
}

.always-red-button {
  @apply bg-red-500 text-white rounded-md uppercase text-12 px-6 py-3 dark:text-gray-300 w-40 font-bold;
}


.pseudo::before {
  display: block;
  content: attr(data-text);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  @apply font-bold h-0;
}

.forceGray > * {
  color: #494949;
  @apply dark:text-gray-300;
}

.overview {
  &-section {
    @apply bg-white rounded-2xl flex flex-col items-center text-green-800 dark:bg-gray-900 dark:text-gray-300;
  }

  &-h2 {
    @apply w-full text-18 dark:text-gray-300;
  }

  &-portfoliohistorychart {
    .net-change {
      @apply border space-y-4 flex flex-col border-gray-450-t-50 p-5 text-14;
    }

    .header-flex {
      @apply flex items-center justify-between;
    }

    .date {
      @apply border border-gray-300 rounded-sm w-28 flex items-center justify-center pl-4 pr-1 h-7;
      -webkit-appearance: none;
    }
  }
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

.last-activity {
  &-row {
    @apply flex justify-between items-center;
  }
}

.action {
  @apply absolute flex flex-col text-gray-800 text-12 bg-white w-36 shadow-lg;

  :last-child {
    border-bottom: 0;
  }

  &-element {
    @apply flex items-center p-4 border-b border-gray-450-t-29 cursor-pointer hover:text-green-300 relative;

    :last-of-type {
      border-bottom: 0;
    }
  }
}

.input {
  @apply bg-gray-100 h-10 px-5 placeholder-gray-450 rounded-lg outline-none text-14 w-full;
  &-dark {
    @apply dark:placeholder-gray-450 dark:text-gray-300;
  }
  &-date {
    @apply rounded-md border border-green-800 px-3 py-1 text-12 text-gray-800 outline-none w-24 dark:border-gray-300;
  }
}

.dropdown {
  @apply h-10 px-5 placeholder-gray-450 rounded-md outline-none text-14 w-full;
}

.select {
  @apply bg-gray-100 h-10 px-5 rounded-lg text-14 w-full text-left flex justify-between items-center;
}

.bg-green-100 {
  background-color: #d5e6de;
}

.bg-red-100 {
  background-color: #fad2d3;
}

.bg-blue-100 {
  background-color: #b3e9fe;
}

.bg-yellow-100 {
  background-color: #f7f0cc;
}

.bg-pink-100 {
  background-color: #fcddec;
}

.bg-orange-100 {
  background-color: #f8dfd0;
}

.bg-purple-100 {
  background-color: #cdcdf3;
}

.infinite-scroll-component {
  overflow: inherit !important;
  overflow-y: visible !important;
}

.custom-scroll {
  //for Firefox
  scrollbar-color: #2f5a5573 white;
  scrollbar-width: thin;

  //for other browsers
  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2f5a5573;
    border-radius: 10px;
  }
}

.custom-scroll-dark {
  scrollbar-color: #bdbdbd80 #242629;
  scrollbar-width: thin;

  //for other browsers
  &::-webkit-scrollbar-track {
    background-color: #242629;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd80;
    border-radius: 10px;
  }
}

.react-calendar__month-view {
  &__weekdays__weekday {
    @apply flex flex-col w-9 h-8 font-bold text-green-300 items-center justify-center;

    abbr {
      @apply text-center no-underline;
    }
  }

  &__days__day {
    @apply w-9 h-8 flex items-center justify-center;

    abbr {
      @apply w-5.5 h-5.5 flex items-center justify-center;
    }
  }

  .tile {
    &-abbr-selected {
      abbr {
        @apply bg-green-800 text-gray-100 rounded-full dark:bg-gray-900;
      }
    }
    &-abbr-disabled {
      @apply cursor-default;

      abbr {
        @apply text-gray-300 cursor-default dark:text-gray-800;
      }
    }
  }
}

.react-calendar__navigation {
  &__label {
    @apply cursor-default;
  }

  &__arrow {
    @apply cursor-default;
  }
}

.bn-table {
  @media all and (min-width: 850px) {
    width: 100%;
  }
}

.calendar-block {
  @apply flex space-x-3 normal-case items-baseline self-start mb-10 dark:text-gray-300;
}

.dots-spinner {
  @include loader07($size: 3px, $color: #54a097);
}

.loading-dots {
  @include loadingDots($color: #54a097);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
